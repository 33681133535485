// Browser Check
require('../assets/styles/oldbrowser.scss');
const supportedBrowsers = require('./supported-browsers');
const FastClick = require('fastclick');

const outdatedElem = document.getElementById('outdated');

function removeElement(elem) {
    if (elem && elem.parentNode && elem.parentNode.removeChild !== undefined) {
        elem.parentNode.removeChild(elem);
    }
}

function setUpWarning(outdatedElem) {
    outdatedElem.style.display = 'block';
    var btnClose = document.getElementById('btnCloseUpdateBrowser');

    btnClose.onclick = function () {
        removeElement(outdatedElem);
        return false;
    };
}

if (supportedBrowsers.test(navigator.userAgent)) {
    removeElement(outdatedElem);
} else {
    setUpWarning(outdatedElem);
}

// Something $mobile class hinzufügen für js?
(
    function() {
        // Workaround für das Menü
        if (!('ontouchstart' in window)) {
            document.body.classList.add('no-touch');
        }
    }
)();
// Something @types
FastClick.attach(document.body);
